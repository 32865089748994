import React from 'react';
import Sumo from '../images/sumo.svg'

const SumoLogo = ({height}) => {
    return (
        <>
            <img src={Sumo} alt='Sumo' className='h-72 md:h-96'/>
        </>
    )
}

export default SumoLogo;