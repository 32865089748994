import React from "react";
import EmailForm from "../components/EmailRegister";
import Layout from "../components/Layout";
import SumoLogo from "../components/Sumo";

const HomePage = () => {
  return (
    <>
      <Layout>
        <div className="h-screen">
          <div className="bg-sumo-blue bg-hero-pattern h-64 lg:h-96 w-full">
            <div className="p-3">
              <div className="flex justify-center pt-10">
                <h1 className="text-center header-text text-2xl sm:text-4xl md:text-6xl lg:text-8xl uppercase font-teko text-sumo-white tracking-wide">
                  A Newsletter With Weight Behind It
                </h1>
              </div>
              <div className="flex justify-center pt-5">
                <h2 className="text-center sm:text-sm lg:text-lg font-montserrat text-white italic">
                  Building your character,
                  discipline and dignity with curated articles on leadership,
                  personal development and mental health.
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-center -mt-16 lg:-mt-32 sumo-logo ">
            <SumoLogo />
          </div>
          <div className="flex justify-center">
            <EmailForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default HomePage;
