import React from "react";

const EmailForm = () => {
  return (
    <>
      <div id="revue-embed" className="pt-4">
        <form
          action="https://www.getrevue.co/profile/mindfulsumo/add_subscriber"
          method="post"
          id="revue-form"
          name="revue-form"
          target="_blank"
        >
          <div className="flex justify-center">
            <input
              className="form-input px-4 py-3 border border-sumo-blue md:w-80"
              placeholder="Your email address..."
              type="email"
              name="member[email]"
              id="member_email"
            />
            <input
              type="submit"
              value="Subscribe"
              name="member[subscribe]"
              id="member_submit"
              className="flex p-4 border border-sumo-blue bg-sumo-blue text-white"
            />
          </div>
          <div className="revue-form-footer pt-10 font-montserrat text-center pl-4 pr-4">
            By subscribing, you agree with Revue’s{" "}
            <a target="_blank" href="https://www.getrevue.co/terms">
              Terms of Service
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://www.getrevue.co/privacy">
              Privacy Policy
            </a>
            .
          </div>
        </form>
      </div>
    </>
  );
};

export default EmailForm;
